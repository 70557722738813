<template>
  <div class="enterpriseAuth">
    <div class="title">企业认证</div>
    <div class="tips" v-if="$store.state.userInfo.company_auth==0">
      请填写你的企业信息，系统审核完成后将以短信的形式通知到您。
    </div>
    <div class="success_tags" v-if="$store.state.userInfo.company_auth == 1">您已通过审核</div>
    <div class="tips" v-if="$store.state.userInfo.company_auth==2">
      系统审核中。。。
    </div>
    <div class="error_tags" v-if="$store.state.userInfo.company_auth == 3">
      系统审核失败：{{ ep.refuse_reason }}。
    </div>
    <div class="logo">
      <div class="logo_text">企业logo</div>
      <uploadImage stats="image" @success="uploadSuccess">
        <div class="logo_img" slot="uploadContent">
          <img :src="ep.image" alt="" v-if="ep.image" />
          <img src="@/assets/img/icon/bg_upload_img.png" alt="" v-else>
        </div>
      </uploadImage>
    </div>
    <div class="enterprise_infos">
      <div class="enterprise_infos_item">
        <label for="name">公司名称</label><br>
        <input id="name" type="text" placeholder="请输入公司名称" v-model="ep.name">
      </div>
      <div class="enterprise_infos_item">
        <label>所属行业</label><br>
        <el-cascader v-model="ep.industry_ids" collapse-tags :props="props" :options="cateGoryConfig.industry" placeholder="请选择所属行业"
          @change="handleChange">
        </el-cascader>
      </div>
      <div class="enterprise_infos_item">
        <label>公司规模</label><br>
        <el-select v-model="ep.scale_id" placeholder="请选择公司规模">
          <el-option v-for="item in cateGoryConfig.scale" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="enterprise_infos_item">
        <label>主营业务</label><br>
        <el-cascader v-model="ep.business_ids" collapse-tags :props="props" :options="cateGoryConfig.business" placeholder="请选择主营业务"
          @change="handleChange">
        </el-cascader>
      </div>
      <div class="enterprise_infos_item">
        <label for="legal">企业法人</label><br>
        <input id="legal" type="text" placeholder="请输入法人姓名" v-model="ep.legal_person">
      </div>
      <div class="enterprise_infos_item">
        <label>企业所在地区</label><br>
        <div class="region_box">
          <regionSelect :value="ep.area_code" @changeRegion="changeRegion"></regionSelect>
        </div>
      </div>
      <div class="enterprise_infos_item">
        <label>企业详细地址</label><br>
        <textarea placeholder="请输入详细地址" v-model="ep.address"></textarea>
      </div>
      <div class="enterprise_infos_item">
        <label>营业执照/安全许可证</label><br>
        <div class="photos">
          <div class="photo_list">
            <div class="photo_item" v-for="(photo,index) in ep.images" :key="index">
              <img :src="photo" alt="">
              <div class="del_photo_btn" @click="delPhoto(index)">
                <img src="@/assets/img/icon/icon_radio_close.png" alt="">
              </div>
            </div>
          </div>
          <div class="photo_upload" v-if="ep.images.length<3">
            <uploadImage stats="images" @success="uploadSuccess">
              <img src="@/assets/img/icon/bg_upload_img.png" alt="" slot="uploadContent">
            </uploadImage>
          </div>
        </div>
      </div>
    </div>
    <button class="submit" @click="submit">提交</button>
  </div>
</template>
  
<script>
import uploadImage from '../../../components/uploadImage/uploadImage.vue';
import regionSelect from '../../../components/regionSelect.vue';
import mixin from '../../../mixin'
export default {
  mixins: [mixin],
  components: {
    uploadImage,
    regionSelect
  },
  data () {
    return {
      ep: {
        name: '',
        image: "",
        scale_id: "",
        industry_ids: "",
        business_ids: "",
        legal_person: "",
        area: "",
        area_code: [],
        address: "",
        images: [],
      },
      props: { multiple: true, value: 'id', label: 'name', children: 'child' },
      cateGoryConfig: {
        business: [],
        industry: [],
        scale: [],
      }
    }
  },
  mounted () {
    this.getCategoryConfig();
    this.getCompanyInfo();
  },
  methods: {
    changeRegion (val) {
      console.log(val, '当前给你啊我跟我0');
      this.ep.area_code = val.area_code;
      this.ep.area = val.area;
      console.log(this.ep, 'dagaga ')
    },
    handleChange (val) {
      console.log(val, '当前节点');
    },
    getCompanyInfo () {
      this.$api.getCompanyInfo().then(res => {
        if (res.data.name) {
          this.ep = res.data;
          this.ep.images = res.data.images_view;
          this.ep.image = res.data.image_view;
          this.ep.area_code = this.ep.area_code.split('/');
          this.ep.scale_id *= 1
          this.ep.industry_ids = res.data.industry_arr;
          this.ep.business_ids = res.data.business_arr;
          console.log(this.ep, "当前企业认证信息");

        }
      })
    },
    getCategoryConfig () {
      this.cateGoryConfig = JSON.parse(localStorage.getItem('commonCateGoryConfig'));
      // this.cateGoryConfig.business = this.cateGoryConfig.business.map(item => {
      //   if (item.child) {
      //     item.children = item.child;
      //   }
      //   return item;
      // })
      console.log(this.cateGoryConfig, '当前分类数据');
    },
    uploadSuccess (val) {
      switch (val.status) {
        case 'image':
          this.ep[val.status] = val.img;
          break;
        case 'images':
          this.ep[val.status].push(val.img);
          break;
      }
    },
    delPhoto (index) {
      this.ep.images.splice(index, 1);
    },
    submit () {
      let params = { ...this.ep };
      params.image = this.del_image_url(params.image);
      params.images = this.del_image_url(params.images);
      params.area_code = `${this.ep.area_code[0]}/${this.ep.area_code[1]}`;
      params.images += '';
      params.business_ids = params.business_ids.map(item => {
        return item[1];
      }) + '';
      params.industry_ids = params.industry_ids.map(item => {
        return item[1];
      }) + '';
      // params.business_ids =  params.business_ids [1];

      params.scale_id += '';
      delete params.image_view;
      delete params.images_view;
      delete params.industry_arr;
      delete params.business_arr;
      console.log(params, '提交表单');
      this.$api.companyAuth(params).then(res => {
        console.log(res, '表单响应');
        if (res.code == 1) {
          this.$message.success(res.msg);
        }
      })
    }
  }

}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.enterpriseAuth {
  padding: 19px 24px 64px;
  background: #fff;
  width: 825px;
  box-sizing: border-box;
  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
  }

  .tips,
  .error_tags,
  .success_tags {
    padding: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #3f63ff;
    line-height: 14px;
    margin: 17px 0 26px;
    background: #ebeef7;
  }
  .error_tags {
    color: #ff4e4e;
    background: #f7e4e4;
  }
  .success_tags {
    background: #e3f0e2;
    color: #379e41;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .logo_text {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      margin-right: 20px;
    }
    .logo_img {
      width: 107px;
      height: 107px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .enterprise_infos {
    display: flex;
    flex-wrap: wrap;

    .enterprise_infos_item {
      flex: 1;
      .region_box {
        /deep/ .el-cascader {
          width: 378px;
          height: 44px;
          margin: 10px 0 32px;
          input {
            height: 44px;
          }
        }
      }
      label {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
      }

      input {
        width: 378px;
        height: 44px;
        margin: 10px 0 32px;
        padding: 13px 14px;
        background: #ffffff;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #e5e5e5;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }

      .el-select,
      .el-cascader {
        width: 378px;
        height: 44px;
        margin: 10px 0 32px;

        input {
          height: 44px;
        }
      }

      textarea {
        width: 767px;
        height: 101px;
        border-radius: 5px 5px 5px 5px;
        margin: 10px 0 32px;
        padding: 13px 14px;
        border: 1px solid #e5e5e5;
        outline-style: none;

        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }

      textarea::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }

      .photos {
        display: flex;
        margin: 19px 0 35px;
        .photo_list {
          display: flex;
          .photo_item {
            width: 140px;
            height: 140px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #e5e5e5;
            margin-right: 22px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .del_photo_btn {
              position: absolute;
              top: 8px;
              right: 14px;
              cursor: pointer;
              img {
                width: 22px;
                height: 22px;
              }
            }
          }
        }

        .photo_upload {
          img {
            width: 140px;
            height: 140px;
            border-radius: 8px 8px 8px 8px;
          }
        }
      }
    }
  }

  .submit {
    padding: 16px 185px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    background: #3f63ff;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
  }
}
</style>